.modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
}

.paper {
  width: 323;
  outline: none;
  padding: 24px 46px 80px;
  border-radius: 4;
}

.logo {
  width: 100%;
  text-align: center;
  margin-bottom: 50;
}

.logo img {
  width: 120;
  height: 23;
  object-fit: contain;
}

.title {
  font-size: 2rem;
  font-weight: 900;
  font-family: 'Averta-Extrabold, Source Sans Pro, sans-serif';
}

.description {
  margin-top: 12;
  margin-bottom: 75;
  font-size: .875rem;
  color: #454545;
  font-family: 'Averta-Bold, Source Sans Pro, sans-serif';
}