.resultContainer {
  margin: 0 20px;
  border-radius: 4px;
  box-shadow: 0 4px 12px 2px rgba(207, 207, 207, 0.5);
  background-color: #dceceb;
}

.resultSection {
  background-color: white;
  padding-top: 40px;
}

.resultSection h3 {
  text-align: center;
  padding: 0 20px;
}

.planItems {
  border-bottom: solid 1px #babec1;
  padding: 33px 0 3px;
}

.resultTitle{
  padding: 0 20px 3px;
}

.resultText{
  -moz-box-pack: justify;
  margin: 0px 0px 10px;
  font-size: 16px;
  color: rgb(137, 136, 142);
}

.resultSectionLast{
  padding: 0 20px 29px;
}

.resultSectionLast .resultTitle{
  padding: 33px 0 27px;
}

.resultCubicFotage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.resultCubicFotage h6:last-child {
  color: black;
}

.resultPlan {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resultPlan h4 {
  font-size: 22px;
  font-family: 'Averta-Regular';
}

.questionInform {
  width: 50%;
  text-align: right;
  margin-left: auto;
  margin-bottom: 25px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  outline: currentcolor none medium;
  min-width: 218px;
  cursor: pointer;
  padding: 0 20px;
  font-family: 'Averta-Bold', sans-serif;
  color: white;
  background-color: #097e77;
  border: solid 1px #097e77;
  width: 100%;
  height: 60px;
  font-size: 14px;
  letter-spacing: 1px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.button:hover, .button:focus {
  opacity: 0.8;
}

.outlineButton {
  background-color: white;
  color: #097e77;
}

.list{
  list-style-type: none;
  padding-left: 0;
  padding-top: 15px;
}

.list li {
  font-size: 13px;
  font-family: 'Averta-Regular';
  color: #454545;
  line-height: 28px;
  text-align: center;
}

.list li img {
  margin-right: 10px;
  width: 10px;
}

@media (min-width: 1024px) {
  .resultContainer {
    /* margin: 0 0 0 40px; */
  }
}
