.addItem {
  padding: 32px 0;
  margin: 20px 0;
  background-color: #e9f6f5;
  border: solid 1px #dceceb;
  border-radius: 4px;
}

.innerContent {
  padding: 0 20px;
  margin-bottom: 30px;
}

.innerContent h5 {
  margin-bottom: 16px;
}

.actionContent {
  display: flex;
  padding: 0 20px;
  flex-direction: row;
  margin-top: 30px;
}

.actionContent button:first-child {
  margin-right: 10px;
}