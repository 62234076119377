.resultItems {
  display: flex;
  justify-content: center;
  text-align: left;
  padding: 0px 0px 80px 0px;
}

.mainContainer {
  width: 100%;
}

.bottom_line {
  display: grid;
  grid-template-columns: 100%;
  width: 100%;
}

.bottom_line_align {
  display: flex;
  justify-content: center;
}

.listContainer {
  margin-bottom: 20px;
}

.planComponent {
  width: 100%;
  max-width: 1140px;
  background-color: white;
  margin: 0 auto;
}

.planTitle {
  padding: 0 20px;
  max-width: 840px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  box-sizing: border-box;
}

.planTitle h2 {
  font-size: 32px;
  margin-bottom: 22px;
}

.planTitle h6 {
  margin-bottom: 25px;
}

.addItemContainer {
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;
}

@media (min-width: 768px) {
  .planTitle h2 {
    font-size: 46px;
  }

  .bottom_line {
    padding: 0 20px;
  }
}

@media (min-width: 1024px) {
  .bottom_line {
    display: grid;
    grid-template-columns: 65% 35%;
    width: 1140px;
  }
}

.submissionModal {
  max-width: 60%;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px 30px;
  background: #fff;
  box-shadow: 5px 0px 10px rgb(0 0 0 / 30%);
}

.submissionModalTitle {
  font-weight: 600;
  text-align: center;
  color: #097e77;
}

.submissionModalSubtitle {
  text-align: center;
}

.submissionModalMessage__success {
  background-color: #097e77;
  color: #fff;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.submissionModalMessage__error {
  background-color: red;
  color: #fff;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.submissionButton {
  background-color: #097e77;
  border: 0;
  color: #fff;
  padding: 10px 30px;
  font-size: 16px;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
}

.submissionFormGroup {
  padding: 30px 0px;
  width: 100%;
  text-align: left;
}

.submissionFormLabel {
  font-weight: 600;
  display: block;
  padding-bottom: 5px;
}

.submissionFormControl {
  padding: 1rem 0.8rem;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #aaa;
  border-radius: 3px;
}

.submissionFormError {
  color: red;
  padding-top: 5px;
  font-size: .9rem;
}

.submissionModalClose:after {
  content: '';
  background-image: url("./../../assets/svgs/close.svg");
  position: absolute;
  right: 20px;
  top: 20px;
  display: block;
  transition: transform 300ms;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.notification {
  position: fixed;
  right: 50px;
  top: 50px;
  width: 400px;
  color: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, .3);
}

.notification__success {
  background-color: #097e77;
}

.notification__error {
  background-color: red;
}

.notificationContent {
  padding-right: 0.5rem;
}

.notificationClose {
  cursor: pointer;
}

.notificationClose:after {
  content: '';
  background-image: url("./../../assets/svgs/close.svg");
  display: block;
  transition: transform 300ms;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
