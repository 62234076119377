.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.header {
  padding: 26px 0 17px;
  display: flex;
  align-items: center;
}

.header__container {
  width: 100%;
  margin: 0 auto;
  max-width: 1368px;
  padding: 0 36px;
  text-align: left;
}

.header img {
  width: 150px;
  height: 30px;
}

.App-link {
  color: #61dafb;
}

@media screen and (max-width: 900px) {
  .header__container {
    padding: 0 20px;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  max-width: 1140px;
  margin: 0 auto;
}

[class^="col-"] {
  position: relative;
  width: 100%;
  min-height: 1px;
}

.col-two-thirds {
  flex: 0 0 65%;
  max-width: 65%;
}

.col-one-thirds {
  flex: 0 0 35%;
  max-width: 35%;
}

@media screen and (max-width: 800px) {
  .col-two-thirds {
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  .col-one-thirds {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

button:disabled,
button[disabled]{
  opacity: .5;
  background-color: #aaa;
  border-color: #aaa;
  cursor: not-allowed;
}
