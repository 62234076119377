@font-face {
  font-family: 'Averta-Regular';
  src: local('Averta-Regular'), url('./assets//fonts/Averta-Regular.otf');
}

@font-face {
  font-family: 'Averta-Bold';
  src: local('Averta-Bold'), url('./assets//fonts/Averta-Bold.otf');
}

body {
  margin: 0;
  font-family: 'Averta-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Averta-Regular', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1, .h1 {
  font-size: 64px;
  color: black;
  font-family: 'Averta-Bold';
  margin: 0;
}

h2, .h2 {
  font-size: 46px;
  color: black;
  font-family: 'Averta-Bold';
  margin: 0;
  line-height: 1;
}

h3, .h3 {
  font-size: 32px;
  color: black;
  font-family: 'Averta-Bold';
  margin: 0;
  line-height: 1;
}

h4, .h4 {
  font-size: 24px;
  color: black;
  font-family: 'Averta-Bold';
  margin: 0;
  line-height: normal;
}

h5, .h5 {
  font-size: 16px;
  color: black;
  font-family: 'Averta-Bold';
  margin: 0;
  line-height: normal;
}

h6, .h6 {
  font-size: 14px;
  color: #808080;
  font-family: 'Averta-Regular';
  margin: 0;
  line-height: 1.43;
}

.small {
  font-size: 13px;
  color: #808080;
  font-family: 'Averta-Regular';
  margin: 0;
}
