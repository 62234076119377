.counter {
  display: flex;
  width: 100%;
  min-width: 280px;
  box-sizing: border-box;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  padding: 14px 20px;
}

.counterLeft {
  margin-right: 12px;
}

.counterLeft h5 {
  margin-bottom: 3px;
}

.counterRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 100px;
}

.counterButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  font-size: 28px;
  color: white;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0;
  background-color: transparent;
}

.counterButton:hover {
  opacity: 0.8;
}

.counterButtonDisabled:hover {
  opacity: 1;
}

.counterValue {
  flex: 1;
  text-align: center;
  color: black;
}
