.show {
  display: block;
}

.hide {
  display: none;
}

.panel {
  margin: 20px 20px 0px;
  border-radius: 4px;
  box-shadow: 0 4px 12px 2px rgba(207, 207, 207, 0.5);
  background-color: #dceceb;
}

.panel-heading {
  padding: 20px 0px;
  background-color: #fff;
}

.panel-heading h3 {
  text-align: center;
}

.panel-body {
  padding: 20px;
  position: relative;
}

.booking {
  display: flex;
  padding: 5px 0px;
  justify-content: space-between;
  color: #454545;
}

.badge {
  padding: 3px 6px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background-color: #097e77;
  border-radius: 3px;
}

.panel__submit {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  outline: medium none currentcolor;
  min-width: 218px;
  cursor: pointer;
  padding: 0 20px;
  font-family: "Averta-Bold",sans-serif;
  color: #fff;
  background-color: #097e77;
  border: 1px solid #097e77;
  width: 100%;
  height: 60px;
  font-size: 14px;
  letter-spacing: 1px;
  border-radius: 4px;
  margin: 10px 0px;
}

.panel__submit:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.loader-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(9, 126, 119, 0.3);
}

.component-loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #097e77;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: auto;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
