
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  outline: currentcolor none medium;
  cursor: pointer;
  padding: 0 20px;
  font-family: 'Averta-Bold', sans-serif;
  color: white;
  background-color: #097e77;
  border: solid 1px #097e77;
  width: 100%;
  height: 60px;
  font-size: 14px;
  letter-spacing: 1px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.button:hover, .button:focus {
  opacity: 0.8;
}

.outlineButton {
  background-color: white;
  color: #097e77;
}

.mdSizeButton {
  height: 40px;;
}