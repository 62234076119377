
.Collapsible {
  background-color: white;
}

.Collapsible__contentInner {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0;
  border: 1px solid #babec1;
  border-top: 0;
}

.Collapsible__contentInner p {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}

.Collapsible__contentInner p:last-child {
  margin-bottom: 0;
}

.Collapsible__trigger {
  display: block;
  text-decoration: none;
  position: relative;
  border: 1px solid #097e77;
  border-bottom: solid 1px white;
  padding: 15px 20px;
  font-family: 'Averta-Bold';
  font-size: 16px;
  color: white;
}

.Collapsible__trigger:after {
  content: '';
  transform: rotateZ(180deg);
  background-image: url("./../../assets/svgs/up-arrow.svg");
  position: absolute;
  right: 20px;
  top: 20px;
  display: block;
  transition: transform 300ms;
  line-height: 0px;
  font-size: 24px;
  font-weight: bold;
  width: 14px;
  height: 8px;
}

.Collapsible__trigger.is-open:after {
  transform: rotateZ(360deg);
}

.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey;
}

.CustomTriggerCSS {
  background-color: #097e77;
  transition: blue 200ms ease;
}

.CustomTriggerCSS--open {
  background-color: #097e77;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #CBB700;
  color: black;
}

@media (min-width: 768px) {
  .Collapsible__contentInner > div {
    width: 50%;
  }
  
  .Collapsible__contentInner > div:last-child {
    width: calc(50% + 1px);
  }
}