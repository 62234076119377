.filterResult {
  border: 1px solid #babec1;
  border-top: none;
}

.filterResult > h6 {
  color: #babec1;
  padding: 17px 20px;
}

.filterResultInner {
  display: flex;
  flex-wrap: wrap;
}

.filterResultInner .CounterComponent_counter {
  width: 50%;
  max-width: 50%;
}