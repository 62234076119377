.Loader {
  padding: 30px;
  text-align: center;
}

.Loader > div {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #097e77;
  margin: 4px;
}

.Loader > div:nth-child(1) {
  animation: scale 0.75s cubic-bezier(0.2, 0.68, 0.18, 1.08) 0.12s infinite;
}
.Loader > div:nth-child(2) {
  animation: scale 0.75s cubic-bezier(0.2, 0.68, 0.18, 1.08) 0.24s infinite;
}
.Loader > div:nth-child(3) {
  animation: scale 0.75s cubic-bezier(0.2, 0.68, 0.18, 1.08) 0.36s infinite;
}

@keyframes scale {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  45% {
    transform: scale(0.1);
    opacity: 0.7;
  }
  80% {
    transform: scale(1);
    opacity: 1;
  }
}
