.searchDiv {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: left;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.searchDiv input {
  appearance: none;
  box-shadow: none;
  box-sizing: border-box;
  width: 100%;
  opacity: 1;
  transition: opacity 0.2s linear 0s;
  font-family: "Averta-Regular", "Biotif", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: #454545;
  height: 50px;
  padding: 0px 44px;
  border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  border: solid 1px #e9f6f5;
  border: none;
  text-overflow: ellipsis;
  background-color: #e9f6f5;
  outline: none;
}

.searchIcon {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 20px;
  top: 18px
}

.closeButton {
  width: 13px;
  height: 13px;
  position: absolute;
  right: 20px;
  top: 19px;
  cursor: pointer;
  font-size: 36px;
  line-height: 10px;
  color: #babec1;
}