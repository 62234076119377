.inputComponent {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.inputComponent h6 {
  margin-right: 14px;
  min-width: 88px;
}

.inputComponent input {
  flex: 1;
  height: 50px;
  padding: 15px 16px;
  border-radius: 4px;
  border: solid 1px #babec1;
  background-color: white;
  box-sizing: border-box;
  outline: none;
  font-size: 14px;
  font-family: "Averta-Regular", "Biotif", sans-serif;
  color: #454545;;
  min-width: 0;
}


